<template>
  <Container class="w-full px-0 my-12 md:px-6">
    <figure class="lg:py-12">
      <div ref="container"
           class="keen-slider md:rounded-3xl"
      >
        <div v-for="(item, index) in slides"
             :key="index"
             class="mx-auto"
        >
          <div v-if="item.asset.endsWith('mp4')"
               class="keen-slider__slide"
          >
            <SectionVideo :video-source="item.asset" />
          </div>
          <div v-else
               class="keen-slider__slide w-full h-full md:max-h-[641.25px]"
          >
            <NuxtImg :src="item.asset"
                     :alt="item.caption || ''"
                     class="mx-auto md:rounded-3xl max-md:w-full max-w-full max-h-full"
                     sizes="xs:100vw sm:746px md:984px lg:1240px xl:1440px xxl:1440px"
                     loading="lazy"
            />
          </div>
        </div>
      </div>
      <div v-if="slides.length > 1 || slides.some(slide => slide.caption && slide.caption.length > 0)"
           class="flex flex-col items-center px-6 mt-6 md:justify-between"
      >
        <div class="relative flex items-center justify-center flex-shrink-0 mb-4 w-32"
             v-if="slider && slider.track.details.slides.length > 1"
        >
          <button @click="slider.prev()"
                  class="absolute left-0 p-2 rounded-md select-none hover:bg-gray-100"
          >
            <span class="sr-only">Previous</span>
            <IconArrowRight class="w-4 h-4 rotate-180" />
          </button>

          <div class="flex px-4 tabular-nums">
            {{ current + 1 }} - {{ slider.track.details.slides.length }}
          </div>

          <button @click="slider.next()"
                  class="absolute right-0 p-2 rounded-md select-none hover:bg-gray-100"
          >
            <span class="sr-only">Next</span>
            <IconArrowRight class="w-4 h-4" />
          </button>
        </div>
        <!-- if all captions are empty, do not show -->
        <figcaption v-if="!slides.every(slide => slide.caption === '')"
                    class="text-center"
        >
          {{ slides[current].caption }}
        </figcaption>
      </div>
    </figure>
  </Container>
</template>

<script setup lang="ts">
import { type KeenSliderInstance, useKeenSlider } from 'keen-slider/vue.es';
import { onMounted, ref } from 'vue';

type BlogSliderType = {
  slides: Array<{
    asset: string;
    caption: string;
    type: string;
  }>;
};

defineProps<BlogSliderType>();

const current = ref(0);

const update = (e: KeenSliderInstance) => {
  current.value = e.track.details.rel;
};

onMounted(async () => {
  // temporary fix for route change bug
  setTimeout(() => {
    if (slider.value) slider.value.update();
  }, 200);
});

const [container, slider] = useKeenSlider({
  slides: {
    perView: 1
  },
  loop: true,
  slideChanged: e => update(e)
});
</script>
